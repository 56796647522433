import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BOOKING_SERVICES from '../../services/Booking.service';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import * as yup from 'yup';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AUTH_SERVICE from "../../services/auth.service";
import Link from "../Auth/Login";
import FormHelperText from "./EditProfile";
import apiEndPoint from "../../constant/apiEndpoint";
import AUTH_SEVICES from '../../services/auth.service';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    editorArea: {
        background: '#f7f7f7',
        minHeight:200,
        padding:10
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(7),
    },

    root3: {
        marginTop: 30,
    },
    rootContainer: {
        flexGrow: 1,
        marginTop: 0,
        marginBottom: 50
    },
    formControl: {
        width: '100%',
        marginTop: 10
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    saveBtn: {
        marginTop: 10,
        width: '100%',
    },


}));

const AddServices = () => {
    const history = useHistory();
    const cancel = () => {
        history.push("/profile");
    };
    const defaultCat = [
        {
            id: '1',
            category_name: 'None',
            sub_category_name: 'None',
        },
    ];
    const locations = [
        {
            id: 1,
            name: 'Auckland',
        },
        {
            id: 2,
            name: 'Wellington',
        },
        {
            id: 3,
            name: 'Colombo',
        },
    ];
    const [open, setOpen] = React.useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [countries, setCountries] = React.useState([]);
    const [cities, setCities] = useState([]);
    const [values, setValues] = useState({});
    const [editorState, setEditorState] = useState("");
    const [baseImage, setBaseImage] = useState("");
    const [slots, setSlots] = useState([]);
    const [endSlots, setEndSlots] = useState([]);
    const uploadImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setBaseImage(base64);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const getCategories = () => {
        return new Promise((resolve => {
            BOOKING_SERVICES.getCategories()
                .then(res => {
                    if (res.data.categories) {
                        setCategories(res.data.categories)
                    }
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const getLocations = () => {
        return new Promise((resolve => {
            BOOKING_SERVICES.getCountries()
                .then(res => {
                    setCountries(res.data.countries)
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const getSlots = () => {
        return new Promise((resolve => {
            BOOKING_SERVICES.getSlots()
                .then(res => {
                    setSlots(res.data.slots)
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const getUserData = () => {
        return new Promise((resolve => {
            AUTH_SEVICES.getUserData()
                .then(res => {
                    if (res.data.userData.country === 'null' || res.data.userData.country === null) {
                        history.push("/edit-profile")
                    }
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    useEffect(() => {
        getUserData();
        getSlots();
        getCategories();
        getLocations();
    }, []);
    const handleCloseSnackbar = () => {
        setOpen(false);
    };
    const getSubCatList = (id) => {
        return new Promise((resolve => {
            BOOKING_SERVICES.getSubCategories(id)
                .then(res => {
                    if (res.data.subCategories.length > 0) {
                        setSubCategories(res.data.subCategories)
                    }
                    else {
                        setSubCategories(defaultCat)
                    }
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const changedStart = (id) => {
        let endSlots = [];
            slots.map(( slot , key) => {
                if (key > id) {
                    endSlots.push(slot)
                }
            });
          setEndSlots(endSlots)
    };
    const handleChange = (e) => {
        let val = values;
        val[e.target.name] = e.target.value;
        setValues(val);

    };

    return (
        <div className={classes.root}>
            <Container>

                <Formik
                    initialValues={{
                        subcategory: '',
                        category: '',
                        title: '',
                        price: '',
                        serviceTime: '',
                        serviceEndTime: '',
                        serviceDescription: '',
                        availability: '',
                        image: '',
                    }}
                    validationSchema={yup.object().shape({
                        category: yup.string().required(),
                        subcategory: yup.string().required(),
                        title: yup.string().required(),
                        price: yup.string().required(),
                        serviceTime: yup.string().required(),
                        serviceEndTime: yup.string().required(),
                        serviceDescription: yup.string().required(),

                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        let formValues = values;
                        formValues.image = baseImage;

                        setTimeout(() => {
                            BOOKING_SERVICES.createService(formValues)
                                .then(res => {
                                    console.log(res);
                                    if (res.data.service) {
                                        setAlertMsg('Your Service is created successfully');
                                        setOpen(true);
                                        setTimeout(() => {
                                            history.push("/profile");
                                        }, 1000)
                                    }
                                    else {
                                        setSubCategories(defaultCat)
                                    }
                                }).catch(() => {
                                return false
                            })
                        }, 100);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <h3 style={{color: "#000", margin: 12,}}>Add New Service</h3>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>


                                <Grid item xs={12} sm={4}>

                                    <Grid item xs={12}>
                                        <img src={baseImage} width='96%' />
                                    </Grid>
                                    <Button
                                        label="image"
                                        variant="outlined"
                                        size="large"
                                        color="secondary"
                                        component="label"

                                    >
                                        <PhotoCamera /> Upload Service Image
                                        <input
                                            id="image"
                                            name="image"
                                            onChange={uploadImage}
                                            className={classes.formControl}
                                            type="file"
                                            hidden
                                            accept="image/*"
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel id="category">Category</InputLabel>
                                                <Select
                                                    labelId="category-label"
                                                    id="category"
                                                    name="category"
                                                    label="category"
                                                    onChange={handleChange}
                                                    error= {errors.category && touched.category && errors.category}
                                                    helpertext={touched.category ? errors.category : ''}
                                                >
                                                    {
                                                        defaultCat && defaultCat.length > 0 ? (
                                                            categories.map(cat => {
                                                                return (
                                                                    <MenuItem
                                                                        key={cat.id}
                                                                        value={cat.id}
                                                                        onClick={() => {
                                                                            getSubCatList(cat.id)
                                                                        }}
                                                                    >
                                                                        {cat.category_name}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        ) : ""
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel id="subcategory">Sub Category</InputLabel>
                                                <Select
                                                    labelId="subcategory-label"
                                                    id="subcategory"
                                                    name="subcategory"
                                                    label="subcategory"
                                                    onChange={handleChange}
                                                    error= {errors.subcategory && touched.subcategory && errors.subcategory}
                                                    helpertext={touched.subcategory ? errors.subcategory : ''}
                                                >
                                                    {
                                                        defaultCat && defaultCat.length ? (
                                                            subCategories.map(subCat => {
                                                                return (
                                                                    <MenuItem
                                                                        key={subCat.id}
                                                                        value={subCat.id}

                                                                    >{subCat.sub_cat_name}</MenuItem>
                                                                )
                                                            })
                                                        ) : ""
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Service Title"
                                                id="title"
                                                name="title"
                                                className={classes.formControl}
                                                margin="normal"
                                                variant="outlined"
                                                onChange={handleChange}
                                                error= {errors.title && touched.title && errors.title}
                                                helpertext={touched.title ? errors.title : ''}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl component="fieldset">
                                                <FormControlLabel
                                                    value=""

                                                    control={<Switch
                                                        color="primary"
                                                        onChange={handleChange}
                                                        id="availability"
                                                        name="availability"
                                                    />}
                                                    label="Weekends Available?"
                                                    labelPlacement="start"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        label="Price"
                                        id="price"
                                        name="price"
                                        className={classes.formControl}
                                        margin="normal"
                                        variant="outlined"
                                        type="number"
                                        onChange={handleChange}
                                        startadornment={<InputAdornment position="start">$</InputAdornment>}
                                        error= {errors.price && touched.price && errors.price}
                                        helpertext={touched.price ? errors.price : ''}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="serviceTime">Service Start Time</InputLabel>
                                        <Select
                                            labelId="category-label"
                                            id="serviceTime"
                                            name="serviceTime"
                                            // value="serviceTime"
                                            label="Service Start Time"
                                            onChange={handleChange}
                                            error= {errors.serviceTime && touched.serviceTime && errors.serviceTime}
                                            helpertext={touched.serviceTime ? errors.serviceTime : ''}
                                        >
                                            {
                                                defaultCat && defaultCat.length > 0 ? (
                                                    slots.map((slot, key) => {
                                                        return (
                                                            <MenuItem
                                                                key={slot.id}
                                                                value={slot.start_time}
                                                                onClick={() => {
                                                                    changedStart(key)
                                                                }}
                                                            >
                                                                {slot.start_time}
                                                            </MenuItem>
                                                        )
                                                    })
                                                ) : ""
                                            }
                                        </Select>
                                    </FormControl>

                                    {/*<TextField*/}
                                        {/*label="Service Start Time"*/}
                                        {/*id="serviceTime"*/}
                                        {/*name="serviceTime"*/}
                                        {/*className={classes.formControl}*/}
                                        {/*margin="normal"*/}
                                        {/*variant="outlined"*/}
                                        {/*type="time"*/}
                                        {/*defaultValue="07:30"*/}
                                        {/*onChange={handleChange}*/}
                                        {/*error= {errors.serviceTime && touched.serviceTime && errors.serviceTime}*/}
                                        {/*helpertext={touched.serviceTime ? errors.serviceTime : ''}*/}
                                    {/*/>*/}

                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="serviceTime">Service End Time</InputLabel>
                                        <Select
                                            labelId="category-label"
                                            id="serviceEndTime"
                                            name="serviceEndTime"
                                            label="Service End Time"
                                            onChange={handleChange}
                                            error= {errors.serviceEndTime && touched.serviceEndTime && errors.serviceEndTime}
                                            helpertext={touched.serviceEndTime ? errors.serviceEndTime : ''}
                                        >
                                            {
                                                defaultCat && defaultCat.length > 0 ? (
                                                    endSlots.map( slot => {
                                                        return (
                                                            <MenuItem
                                                                key={slot.id}
                                                                value={slot.start_time}
                                                            >
                                                                {slot.start_time}
                                                            </MenuItem>
                                                        )
                                                    })
                                                ) : ""
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        id="serviceDescription"
                                        name="serviceDescription"
                                        className={classes.formControl}
                                        multiline
                                        rowsMax={4}
                                        variant="outlined"
                                        onChange={handleChange}
                                        error= {errors.serviceDescription && touched.serviceDescription && errors.serviceDescription}
                                        helpertext={touched.serviceDescription ? errors.serviceDescription : ''}
                                    />
                                </Grid>
                            </Grid>

                            {/*<Grid container spacing={2}>*/}
                            {/*<Grid item xs={12}  >*/}
                            {/*<Editor*/}
                            {/*editorState={editorState}*/}
                            {/*editorClassName={classes.editorArea}*/}
                            {/*onEditorStateChange={onEditorStateChange}*/}
                            {/*/>*/}
                            {/*</Grid>*/}
                            {/*</Grid>*/}


                            <Grid container spacing={2}>

                                <Grid item xs={2}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" onClick={() => cancel()} size="large" color="secondary"
                                            className={classes.saveBtn}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>

                            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity="success">
                                    {alertMsg}
                                </Alert>
                            </Snackbar>



                        </form>
                    )}
                </Formik>
            </Container>
        </div>

    )

}
export default AddServices

