import React, {useEffect} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import MoreIcon from '@material-ui/icons/MoreVert';
import {useHistory} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import AUTH_SERVICE from '../services/auth.service';
import apiEndPoint from "../constant/apiEndpoint";


const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    root: {
        background: '#fff',
        color:'#000',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        cursor:'pointer'
    },
    name: {
        fontSize: 14,
        paddingTop: 12,
        textTransform: 'capitalize',
        cursor:'pointer',
    },
    homeLink: {
        fontSize: 14,
        paddingRight: 12,
        color: 'black'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        // display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function PrimarySearchAppBar() {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [warning, setWarning] = React.useState(false);
    const [welcomeUser, setWelcomeUser] = React.useState('');
    const [userImage, setUserImage] = React.useState('');

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const ViewProfile = () => {
        history.push("/profile");
        handleMenuClose();
    };
    const Home = () => {
        history.push("/");
        handleMenuClose();
    };
    const Bookings = () => {
        history.push("/bookings");
        handleMenuClose();
    };
    const logout = () => {
        AUTH_SERVICE.logout()
            .then(res=>{
                if (res.data.success){
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('isLoggedIn');
                    setWelcomeUser('');
                    history.push("/");
                }
            })
            .catch(()=>{
                return false;
            });
        handleMenuClose();
    };
    const menuId = 'primary-search-account-menu';


    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
        </Menu>
    );
    useEffect(() => {
        if(localStorage.getItem('access_token') && localStorage.getItem('isLoggedIn') === 'true'){
           setWarning(false);
           let user = JSON.parse(localStorage.getItem('user'));
           setWelcomeUser('Hi '+user.firstName)
           setUserImage(apiEndPoint.base.baseAPI.local.hostName+'userprofile/'+user.image)

        }else {
            setWarning(true);
        }
    },[setWarning,setWelcomeUser]);


    const renderMenu = (

        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => ViewProfile()}>Profile</MenuItem>
            <MenuItem onClick={() => Bookings()}>My Bookings</MenuItem>
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
        </Menu>
    );
    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.root}>
                <Toolbar >

                        <img className="img-fluid"
                             src={`${process.env.PUBLIC_URL}/staane.png`}
                             alt="logo" width={'15%'} onClick={() => Home()} />

                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        { welcomeUser && welcomeUser != '' ? (
                            <Typography className={ `${classes.name} welcome-user-text`} onClick={() => ViewProfile()}>
                                {welcomeUser}
                            </Typography>
                        ) : <Link href="/login">Login</Link> }
                        { welcomeUser && welcomeUser != '' ? (
                           
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                {/* <AccountCircle/> */}
                                <Avatar src={userImage} className={classes.small} />
                            </IconButton>
                        ) : '' }

                    </div>
                    {/* <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div> */}
                </Toolbar>
            </AppBar>
            {/*{renderMobileMenu}*/}
            {renderMenu  }
            { warning && warning === true ?
                (<Alert severity="warning">
                    You are not login. Please <Link href="/login">Login</Link> or <Link href="/register">Create Account</Link>.
                </Alert>) :''
            }
        </div>
    );
}
