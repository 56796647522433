import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import WorkIcon from '@material-ui/icons/Work';
import { useHistory } from 'react-router-dom';
import BOOKING_SEVICES from '../../services/Booking.service';
import { useParams } from "react-router-dom";
import apiEndPoint from "../../constant/apiEndpoint";
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },

    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(7),
    },
    paper1: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(7),
        textAlign: "center"
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, 0%)'
    },
    root1: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    root3: {
        marginTop: 30,
        height: "100%",
    },
    rootContainer: {
        flexGrow: 1,
        marginTop: 0,
        marginBottom: 50
    },
    pricing: {
        marginTop: 10,

    },
    name: {
        color: "#000",
        margin: 12,
        textTransform: 'capitalize'
    },
    chip: {
        marginTop: 20,
        fontSize: 10,
        color: 'green',
    },
    chipred: {
        marginTop: 20,
        fontSize: 10,
        color: 'red',
    }

}));
const PublicProfile = () => {
    const classes = useStyles();
    const history = useHistory();
    const [myServices, setMyServices] = useState([]);
    const [myProfile, setMyProfileData] = useState([]);
    const [imagePath, setImagePath] = useState("");
    const preventDefault = (event) => event.preventDefault();
    const [serviceImageUrl, setServiceImagePath] = React.useState('');
    const AddService = () => {
        history.push("/add-service");
    };
    let { username } = useParams();
    const EditProfile = () => {
        history.push("/edit-profile");
    };
    const getServices = () => {
        return new Promise((resolve => {
            BOOKING_SEVICES.getServices()
                .then(res => {

                    if (res.data.services) {
                        setMyServices(res.data.services)
                    }

                }).catch(() => {
                    return resolve(false)
                })
        }))
    };
    useEffect(() => {
        if (username) {
            BOOKING_SEVICES.getPublicDetails(username)
                .then(res => {
                    if (res.data) {
                        setMyServices(res.data.services);

                        if (res.data.user.image) {
                            let path = apiEndPoint.base.baseAPI.local.hostName + 'userprofile/' + res.data.user.image;
                            setImagePath(path);
                        }
                        if (res.data.services) {
                            let url = apiEndPoint.base.baseAPI.local.hostName + 'serviceImages/';
                            setServiceImagePath(url);
                        }
                        setMyProfileData(res.data.user);
                    }
                }).catch(() => {
                    history.push("/");
                });
        }
    }, []);

    return (
        <div className={classes.root}>
            <Container>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paper1}>
                            <Avatar alt="Remy Sharp"
                                src={imagePath ? imagePath : ""}
                                className={classes.large} />
                            <h3 className={classes.name}>{myProfile.firstName} {myProfile.lastName}</h3>
                            <h5>{myProfile.username}</h5>

                            <List className={classes.root3}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Speciality " secondary={myProfile.speciality} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Qualifications" secondary={myProfile.qualification} />
                                </ListItem>

                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Location" secondary={myProfile.get_country ? myProfile.get_country.name : ""} />
                                </ListItem>

                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} className={classes.rootContainer}>
                        {/* <Box display={{ lg: "block", md: "block", xs: "none", sm: 'none', }} >
                            <Paper className={classes.paper} display={{ xs: "none", lg: "block" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <h3>My Services</h3>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box> */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h3 style={{marginTop: 60, marginBottom: -20}}>My Services</h3>
                            </Grid>
                            {
                                myServices && myServices.length ? (
                                    myServices.map(service => {
                                        return (
                                            <Grid item xs={12} sm={6} md={6} lg={4}>

                                                <Card className={classes.root3}>
                                                    <CardActionArea>
                                                        <CardMedia
                                                            className={classes.media}
                                                            image={serviceImageUrl + service.image}
                                                            title="Contemplative Reptile"
                                                        />
                                                        <CardContent>
                                                            <Typography gutterBottom variant="h6" component="h2">
                                                                {service.service_title}
                                                            </Typography>
                                                            <Typography variant="body2" noWrap color="textSecondary"
                                                                component="p">
                                                                {service.description}
                                                            </Typography>
                                                            <Typography variant="p" color="textPrimary" component="p"
                                                                className={classes.pricing}>
                                                                Category: {service.sub_cat_name}
                                                            </Typography>
                                                            <Typography variant="p" color="textPrimary" component="p"
                                                                className={classes.pricing}>
                                                                General
                                                                Hour: {service.service_time} - {service.service_end_time}
                                                            </Typography>
                                                            <Typography variant="p" color="textPrimary" component="p">
                                                                Price: {service.price} LKR

                                                            </Typography>
                                                            {service.available ? (
                                                                <Chip
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className={classes.chip}
                                                                    label="Weekends Available"
                                                                    clickable
                                                                    color="default"
                                                                    deleteIcon={<DoneIcon />}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className={classes.chipred}
                                                                    label="Weekends Not Available"
                                                                    clickable
                                                                    color="default"
                                                                    deleteIcon={<DoneIcon />}
                                                                />
                                                            )}

                                                        </CardContent>
                                                    </CardActionArea>
                                                    <CardActions>
                                                        <Link href={`/service/${service.slug}`} >
                                                            <Button variant="outlined" size="small" color="primary">
                                                                View
                                                            </Button>
                                                        </Link>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )
                                    })
                                ) : ""
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )

}
export default PublicProfile
