import apiEndPoint from '../constant/apiEndpoint'
import HttpService from './Http.service'

const AUTH_SERVICE = {

    register : params => {
        const url = apiEndPoint.auth.register;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {

                    return  resolve(res)
                })
                .catch(err => {
                    console.log(err);
                    return  reject(err)
                })
        })
    },
    login : params => {
        const url = apiEndPoint.auth.login;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    resetPassword : params => {
        const url = apiEndPoint.auth.resetPassword;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    logout : params => {
        const url = apiEndPoint.auth.logout;
        return new Promise((resolve , reject) => {
            HttpService.get(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getUserData : params => {
        const url = apiEndPoint.auth.getUser;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    resendEmail : params => {
        const url = apiEndPoint.auth.resendEmail;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    updateProfile : params => {
        const url = apiEndPoint.auth.updateProfile;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    updatePatientProfilePicture : params => {
        const url = apiEndPoint.auth.updatePatientProfilePicture;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    }

};

export default AUTH_SERVICE;