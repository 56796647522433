import React, {useEffect, useState} from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

    checkmark: {
        marginTop:50 ,
        fontSize:30,
        color:'green',
    },
}));
const EmailNotVerify = () => {
    const classes = useStyles();
    return (<div>
        <center>
            <CancelIcon style={{ color:'red',  fontSize:50, marginTop:50 }}/>
            <h3>Your email address not verified due to link expire or something else</h3>
        </center>
    </div>)
};

export default EmailNotVerify