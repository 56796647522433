import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import TabContext from '@material-ui/lab/TabContext';
import Typography from '@material-ui/core/Typography';
import BOOKING_SEVICES from '../../services/Booking.service';
import Chip from '@material-ui/core/Chip';

import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DataGrid } from '@material-ui/data-grid';
import { useDemoData } from '@material-ui/x-grid-data-generator';
import Link from "@material-ui/core/Link/Link";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(7),
    },
    root3: {
        marginTop: 30,
    },
    rootContainer: {
        flexGrow: 1,
        marginTop: 0,
        marginBottom: 50
    },
    formControl: {
        width: '100%',
        marginTop: 10
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    saveBtn: {
        marginTop: 10,
        width: '100%',
    },
    copyButton: {
        float: 'right',
        marginRight: 5,
        marginTop: 5,
        color: 'cadetblue'
    },
    link: {
        fontSize: 12,
        marginTop: 15,
        padding: 5,
        borderRadius: 5,
        background: '#303030',
        color: '#fff',
    },
    dialogHead: {
        background: 'linear-gradient(45deg, #5f2c82 30%, #49a09d 90%)',
        color: '#fff',
    },
    checkMark: {
        fontSize: 68,
        color: '#4caf50'
    },
    password: {
        marginTop: 5
    }
}));

function createData(bookingFrom, service, time, amount, link) {
    return {bookingFrom, service, time, amount, link};
}

const rows = [
    createData('Eshan Perera', 'Dental', '21:15', '$200', 'View'),
    createData('John Deo', 'Dental', '21:15', '$200', 'View')

];

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const MyBookings = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [viewBooking, setViewBooking] = useState(false);
    const [meetingData, setMeetingData] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(false);
    const [iamClient, setIamClient] = useState([]);
    const [theyClient, setTheyClient] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const handleClose = () => {
        setViewBooking(false);
    };
    const geBookings = () => {
        BOOKING_SEVICES.getBooking()
            .then(res => {
                if (res) {
                    setIamClient(res.data.iAmClient)
                    setTheyClient(res.data.theyClient)
                }
            }).catch(() => {
        })
    };
    const viewSingleBooking = (data) => {
        return new Promise((resolve) =>{
            const appointment = {
                appointmentId: data.id,
            };
            setSelectedAppointment(data);
            BOOKING_SEVICES.getMeetingData(appointment)
                .then(res => {
                    if (res.data) {
                            setMeetingData(res.data.meeting);
                        setViewBooking(true);
                    }
                }).catch(() => {
                return false;
            })
        });

    };
    const { data } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 1000,
        maxColumns: 6,
    });
    useEffect(() => {
        geBookings();
    }, []);
    return (
        <div className={classes.root}>
            <Container>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <h3 style={{color: "#000", margin: 12,}}>My Bookings</h3>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item xs={12} className={classes.root}>
                        <TabContext value={value}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="I am Client" {...a11yProps(0)} />
                                <Tab label="They are clients" {...a11yProps(1)} />

                            </Tabs>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="caption table">

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>BOOKING ID</TableCell>
                                                    <TableCell align="center"> DATE</TableCell>
                                                    <TableCell align="center">START Time</TableCell>
                                                    <TableCell align="center">STATUS</TableCell>
                                                    <TableCell align="center">Action </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {iamClient.map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            STN{row.id}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center">{moment(row.booking_date).format('LL')}</TableCell>
                                                        <TableCell align="center">{row.get_time.start_time}</TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                row.status === 1 ? (
                                                                    <Chip color="success" size="small" label="Waiting"
                                                                          deleteIcon={<DoneIcon/>}/>
                                                                ) : row.status === 2 ? (
                                                                    <Chip color="success" size="small" label="Completed"
                                                                          deleteIcon={<DoneIcon/>}/>
                                                                ) : (<Chip color="success" size="small" label="Canceled"
                                                                           deleteIcon={<DoneIcon/>}/>)

                                                            }

                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Button onClick={() => viewSingleBooking(row)}
                                                                    variant="outlined" size="small" color="primary"
                                                                    className={classes.margin}>
                                                                VIEW
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="caption table">

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Booking From</TableCell>
                                                    <TableCell align="right"> Service</TableCell>
                                                    <TableCell align="right">Time</TableCell>
                                                    <TableCell align="right">Amount</TableCell>
                                                    <TableCell align="right">Action </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {theyClient.map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            STN{row.id}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center">{moment(row.booking_date).format('LL')}</TableCell>
                                                        <TableCell align="center">{row.get_time.start_time}</TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                row.status === 1 ? (
                                                                    <Chip color="success" size="small" label="Waiting"
                                                                          deleteIcon={<DoneIcon/>}/>
                                                                ) : row.status === 2 ? (
                                                                    <Chip color="success" size="small" label="Completed"
                                                                          deleteIcon={<DoneIcon/>}/>
                                                                ) : (<Chip color="success" size="small" label="Canceled"
                                                                           deleteIcon={<DoneIcon/>}/>)

                                                            }

                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Button onClick={() => viewSingleBooking(row)}
                                                                    variant="outlined" size="small" color="primary"
                                                                    className={classes.margin}>
                                                                VIEW
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                    </TableContainer>
                                </TabPanel>
                            </SwipeableViews>
                        </TabContext>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                fullScreen={fullScreen}
                open={viewBooking}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Booking Information"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div >
                            <Typography variant="button" display="block" gutterBottom>
                                Meeting Date : {meetingData.start_time} (Sri Lankan Timezone)
                            </Typography>
                            <Typography variant="button" display="block" gutterBottom>
                                Joining Person : {selectedAppointment.get_provider_data ? selectedAppointment.get_provider_data.firstName +" "+selectedAppointment.get_provider_data.lastName :
                                selectedAppointment.getClientData ? selectedAppointment.getClientData.firstName +" "+selectedAppointment.getClientData.lastName :
                                ""}
                            </Typography>
                            <Typography variant="button" display="block" gutterBottom>
                                Meeting ID :
                            </Typography>
                            <Typography variant="p">
                                <Link className={classes.link} >
                                    {meetingData.meeting_id}
                                </Link>
                            </Typography>
                            <Typography variant="button" display="block" gutterBottom>
                                Meeting Link :
                            </Typography>
                            <Typography variant="p">
                                <Link className={classes.link} >
                                    {meetingData.meeting_join_url}
                                </Link>
                            </Typography>
                            <Typography variant="button" className={classes.password} display="block" gutterBottom>
                                Meeting Password :{meetingData.meeting_password}
                            </Typography>
                            {/*<FileCopyIcon className={classes.copyButton}/>*/}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};
export default MyBookings
