import React, {useRef,useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles, ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import Typography from "@material-ui/core/Typography/Typography";
import AvTimerOutlinedIcon from '@material-ui/icons/AvTimerOutlined';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Link from '@material-ui/core/Link';
import BOOKING_SEVICES from '../../services/Booking.service';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'cursive',
        ].join(','),
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            whiteSpace: 'nowrap',
            marginBottom: theme.spacing(1),
            background: 'linear-gradient(45deg, #5f2c82 100%, #49a09d 90%)',
            color: '#fff',
            fontWeight: 300,
            borderRadius: 0
        },
        daySlot: {
            padding: theme.spacing(1),
            textAlign: 'center',
            whiteSpace: 'nowrap',
            marginBottom: theme.spacing(1),
            fontWeight: 400,
            cursor: 'pointer'
        },
    daySlotWeekend: {
            padding: theme.spacing(1),
            textAlign: 'center',
            whiteSpace: 'nowrap',
            marginBottom: theme.spacing(1),
            fontWeight: 400,
            backgroundColor:'#fbe1c1',
            cursor: 'pointer'
        },
        daySlotNotAvailable: {
            padding: theme.spacing(1),
            textAlign: 'center',
            whiteSpace: 'nowrap',
            marginBottom: theme.spacing(1),
            fontWeight: 700,
            cursor: 'pointer'
        },
        timeArea: {
            marginTop: 20
        },
        clock: {
            fontSize: 18,
            marginRight: 5
        },
        backdrop: {
            zIndex: 9999,
            color: '#fff',
        },
        copyButton: {
            float: 'right',
            marginRight: 5,
            marginTop: 5,
            color: 'cadetblue'
        },
        link: {
            fontSize: 12,
            marginTop: 15,
            padding: 5,
            borderRadius: 5,
            background: '#303030',
            color: '#fff',
        },
        dialogHead: {
            background: 'linear-gradient(45deg, #5f2c82 30%, #49a09d 90%)',
            color: '#fff',
        },
        checkMark: {
            fontSize: 68,
            color: '#4caf50'
        },
        password: {
            marginTop: 5
        },
        paymentMsg : {
            marginTop :10,
            marginBottom :10,
            color:"#4caf50",
            fontWeight:700
        }
    }))
;

const AvailableSlot = (
    props
) => {
    const history = useHistory();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [linkShow, setLinkShow] = useState(false);
    const [showClose, setShowClose] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Confirmation Required');
    const [link, setLink] = useState('');
    const [password, setPassword] = useState('');
    const [slots, setSlots] = useState('');
    const [alertMsg, setAlertMsg] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [paidFor, setPaidFor] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [yesDisable, setYesDisable] = React.useState(false);
    const [msg, setMsg] = React.useState("");
    const [weekendNot, setWeekenNot] = React.useState("");
    const paypal =  React.useRef(null);
    let selectedDayIni = {
        slotId: null,
        dayName: '',
        startTime: '',
        providerId: null,
        serviceId: null,
        date: '',
        price: 0,
        description : ""
    };
    const product = {
        price: 70,
        description: "doctor",

    };
    const openModal = Boolean(props.open);
    const handleNotAvailable = () => {
      setWeekenNot(true)
    };
    const handleBook = (startTime, slotId, dayName, date) => {

        if (!localStorage.getItem('access_token') && localStorage.getItem('isLoggedIn') != 'true') {
            history.push('/login');
        }
        setDialogTitle('Confirmation Required');
        setLinkShow(false);
        selectedDayIni.slotId = slotId;
        selectedDayIni.startTime = startTime;
        selectedDayIni.dayName = dayName;
        selectedDayIni.providerId = props.serviceData.slug;
        selectedDayIni.serviceId = props.serviceData.id;
        selectedDayIni.date = date;
        selectedDayIni.price = props.serviceData.price;
        selectedDayIni.description = props.serviceData.slug;
        setSelectedDay(selectedDayIni);
        setConfirmOpen(true);

    };
    const handleConfirmClose = () => {
        setMsg("");
        setYesDisable(false);
        setShowClose(false);
        setConfirmOpen(false);
        props.setOpen(false);
    };
    const handleCloseSnackbar = () => {
        setOpen(false);
    };
    const handleSuccess = () => {
        // history.push("/thankyou");
    };
    const handleConfirm = (bookingData) => {
        setLink("");
        setPassword("");
        setOpenBackDrop(true);

    };
    const setPayment = () => {
        setYesDisable(true);
        setMsg("Please make the payment for continue your booking");
        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent :"CAPTURE",
                    purchase_units: [{
                        description: selectedDay.description,
                        amount: {
                            currency_code: "USD",
                            value: 3,
                        }
                    }]
                });
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();

                if(order.status === "COMPLETED"){
                    setPaidFor(true);
                    setLink("");
                    setPassword("");
                    setOpenBackDrop(true);
                    const bookingData = {
                        providerId: selectedDay.providerId,
                        serviceId: selectedDay.serviceId,
                        slotId: selectedDay.slotId,
                        date: selectedDay.date,
                        time: selectedDay.startTime,
                        paymentInfo : {
                            service_id : selectedDay.serviceId,
                            amount : selectedDay.price,
                            payee_email : order.payer.email_address,
                            description : selectedDay.description,
                            reference : order.id,
                            status : order.status,
                        }
                    };
                    return new Promise((resolve => {
                        BOOKING_SEVICES.creteBooking(bookingData)
                            .then(res => {
                                setOpenBackDrop(false);
                                setOpen(true);
                                setAlertMsg('Your booking is created successfully');
                                setDialogTitle('Your booking is created successfully');
                                setLinkShow(true);
                                setShowClose(true);
                                if (res.data.meeting) {
                                    setLink(res.data.meeting.meeting_join_url);
                                    setPassword(res.data.meeting.meeting_password);
                                }
                            }).catch(() => {
                            return false;
                        })

                    }))
                }


            },
            onError : (err) => {
                console.log(err)
            }
        }).render(paypal.current);
    };
    useEffect(() => {
        if (props && props.availableTimes) {
            setSlots(props.availableTimes.availableTimes);

        }
    }, [slots, props.availableTimes]);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Dialog
                    open={openModal}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth='md'
                    fullWidth={true}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title"
                                 className={classes.dialogHead}>{"Available Time"}</DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom variant="subtitle2">
                            Dr. John Doe
                        </Typography>

                        <Typography variant="caption" color="textSecondary" className={classes.description}
                                    component="p">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </Typography>

                        <Grid container className={classes.timeArea} spacing={2}>
                            {slots && props.availableTimes ?

                                slots.map(slotMain => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Paper className={classes.paper}>
                                                {slotMain.dayName}
                                                <div>
                                                    {slotMain.dayOnly}
                                                </div>
                                            </Paper>

                                            {slotMain.slots.map(singleSlot => {
                                                return (
                                                    <Paper disabled={true}
                                                        onClick={() => props.serviceData.available === null && (slotMain.dayName ==='Sunday' || slotMain.dayName ==='Saturday')  ?
                                                            handleNotAvailable() : handleBook(singleSlot.start_time, singleSlot.id, slotMain.dayName, slotMain.day)

                                                        }
                                                        className={
                                                            props.serviceData.available === null && (slotMain.dayName ==='Sunday' || slotMain.dayName ==='Saturday')  ?
                                                                classes.daySlotWeekend : classes.daySlot

                                                        }>
                                                        <AvTimerOutlinedIcon
                                                            className={classes.clock}/> {singleSlot.start_time}
                                                    </Paper>
                                                )
                                            })}


                                        </Grid>
                                    );
                                })
                                : (
                                    ''
                                )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => props.setOpen(false)} variant="outlined" color="primary">
                            Close
                        </Button>
                        <Button
                            onClick={() => props.setOpen(false)}
                            variant="contained" color="primary"
                            startIcon={<PersonIcon/>}
                        >
                            See Profile
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={confirmOpen}
                    onClose={handleConfirmClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>

                        {linkShow && linkShow === true ? (
                            <DialogContentText id="alert-dialog-description">
                                <div className={classes.linkArea}>
                                    <center>
                                        <CheckCircleOutlineIcon className={classes.checkMark}/>
                                    </center>
                                    <Typography variant="button" display="block" gutterBottom>
                                        Meeting Date : {selectedDay.date} @ {selectedDay.startTime} (Sri Lankan
                                        Timezone)
                                    </Typography>
                                    <Typography variant="button" display="block" gutterBottom>
                                        Meeting Link :
                                    </Typography>
                                    <Typography variant="p">
                                        <Link className={classes.link} href={link}>
                                            {link}
                                        </Link>
                                    </Typography>
                                    <Typography variant="button" className={classes.password} display="block"
                                                gutterBottom>
                                        Meeting Password : {password}
                                    </Typography>
                                    <FileCopyIcon className={classes.copyButton}/>
                                </div>
                            </DialogContentText>
                        ) : (
                            <DialogContentText id="alert-dialog-description">
                                Are you sure, you want to book this service at {selectedDay.startTime} AM on
                                this {selectedDay.dayName} (`{selectedDay.date}`) ?

                                <div className={classes.paymentMsg}>
                                    {msg}
                                </div>

                                <div ref={paypal}></div>
                            </DialogContentText>
                        )}

                    </DialogContent>
                    <DialogActions>
                        {showClose === false ? (
                            <div>
                                <Button onClick={setPayment}  disabled={yesDisable}   color="primary">
                                    YES
                                </Button>
                                <Button onClick={handleConfirmClose} color="primary" autoFocus>
                                    NO
                                </Button>
                            </div>

                        ) : (<Button onClick={() => handleConfirmClose()} color="primary" autoFocus>
                            Finish
                        </Button>)}


                    </DialogActions>
                </Dialog>
                <Dialog
                    open={weekendNot}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Sorry"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                           Sorry. I am not available in weekend, Why don't you try in a week day.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setWeekenNot(false)}} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop className={classes.backdrop} open={openBackDrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success">
                        {alertMsg}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </div>
    );
};

export default (AvailableSlot)
