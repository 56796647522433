import axios from 'axios'
const instant = axios.create();


const httpService = {

    get : (url) => {
        return new Promise((resolve, reject) => {
            instant.get(url,{
                headers: {
                    Accept: 'application/json',
                    Authorization: localStorage.getItem('access_token') ? 'Bearer '+ localStorage.getItem('access_token') : null,
                }}).then(res => resolve(res)
            ).catch(error => {

                if (error.response.status && error.response.status === 401){
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('isLoggedIn');
                    window.location.reload();
                }
            })
        })
    },
    post : (url,params) => {
        return new Promise((resolve, reject) => {
            instant.post(url,params,{
                headers: {
                    Accept: 'application/json',
                    Authorization: localStorage.getItem('access_token') ? 'Bearer '+ localStorage.getItem('access_token') : null,
                }}).then(
                res => resolve(res)
            ).catch(
                error => {

                    if (error.response.status === 401){
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user');
                        localStorage.removeItem('isLoggedIn');
                    }else {
                        return reject(error)
                    }

                }
            )
        })
    },
    put : (url,params) => {
        return new Promise((resolve, reject) => {
            instant.put(url,params).then(
                res => resolve(res)
            ).catch(
                error => {
                    if (error.response.status === 401){
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user');
                        localStorage.removeItem('isLoggedIn');
                    }
                }
            )
        })
    },
    delete : (url,params) => {
        return new Promise((resolve, reject) => {
            instant.delete(url,params).then(
                res => resolve(res)
            ).catch(
                error => {
                    if (error.response.status === 401){
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user');
                        localStorage.removeItem('isLoggedIn');
                    }
                }
            )
        })
    }
};

export default httpService