import React, {useEffect, useState} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import WorkIcon from '@material-ui/icons/Work';
import LocationOn from '@material-ui/icons/LocationOn';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import {red} from '@material-ui/core/colors';
import {useHistory} from 'react-router-dom';
import {useParams} from "react-router-dom";
import AvailableSlot from '../Reusable/AvailableSlot.component';
import BOOKING_SEVICES from "../../services/Booking.service";
import Link from '@material-ui/core/Link';
import apiEndPoint from "../../constant/apiEndpoint";
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles((theme) => ({
    small: {
       
        marginRight:theme.spacing(1),  

    },
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(7),
    },

    root_: {
        flexGrow: 1,
        // marginTop: theme.spacing(7),
        // marginBottom: theme.spacing(7),
    },

    paper: {
        // padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(3),
        textAlign: "center"
    },
    paper1: {
        // padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        // marginTop: theme.spacing(7),
        textAlign: "center",
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, 0%)'
    },
    root1: {
        maxWidth: 345,
    },
    media: {
        height: 340,
    },
    root3: {
        // marginTop: 60,
    },
    rootContainer: {
        flexGrow: 1,
        marginTop: 0,
        // marginBottom: 50
    },
    pricing: {
        marginTop: 10,

    },
    serviceHead: {
        padding: 10,

    },
    Act: {
        width: '100%',
    },
    avatar: {
        backgroundColor: red[500],
    },
    chip: {
        marginTop: 20,
        fontSize: 10,
        color: 'green',
    },
    chipred: {
        marginTop: 20,
        fontSize: 10,
        color: 'red',
    },

}));

const ServiceView = () => {
    const profileImageUrl = apiEndPoint.base.baseAPI.local.hostName + 'userprofile/';
    const classes = useStyles();
    const history = useHistory();
    const PublicProfile = () => {
        history.push("/public/" + serviceData.username);
    };
    let {id} = useParams();
    const [open, setOpen] = useState(false);
    const [availableTimes, setAvailbleTimes] = useState(false);
    const [serviceData, setServiceData] = useState([]);
    const [serviceImageUrl, setServiceImagePath] = React.useState('');
    const getAvailableTimes = (slug) => {
        if (slug != null) {
            BOOKING_SEVICES.checkAvailabilityThisWeek(slug)
                .then(res => {
                    setAvailbleTimes(res.data);
                }).catch(() => {
            });
            setOpen(true);
        }

    };


    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (id) {
            BOOKING_SEVICES.getServiceDetailsUser(id)
                .then(res => {
                    if (res.data.services) {
                        setServiceData(res.data.services)
                    }
                    if (res.data.services) {
                        let url = apiEndPoint.base.baseAPI.local.hostName + 'serviceImages/';
                        setServiceImagePath(url);

                    }

                }).catch(() => {
                history.push("/");
            });
        }
    }, []);

    return (
        <div className={classes.root}>
            <Container>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={8} className={classes.rootContainer}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root3}>
                                    <CardActionArea>
                                        <Typography gutterBottom variant="h5" component="h2"
                                                    className={classes.serviceHead}>
                                            {serviceData.service_title ? serviceData.service_title : ''}
                                        </Typography>
                                        <CardMedia
                                            className={classes.media}
                                            image={serviceImageUrl + serviceData.image}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>Hi there,</p>
                                                <p>{serviceData.description ? serviceData.description : ''}</p>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paper1}>
                            <List >
                                <ListItem>
                                    <ListItemAvatar>

                                    <Avatar src={serviceData.profileImage ? profileImageUrl+serviceData.profileImage : ""} className={classes.small} />  
        
                                    </ListItemAvatar>
                                    <ListItemText primary="Service Provider"
                                                  secondary={serviceData.fullName ? serviceData.fullName : ''}/>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => PublicProfile()}
                                    >
                                        View Profile
                                    </Link>
                                </ListItem>
                            </List>
                        </Paper>
                        <Paper className={classes.paper}>
                            <List className={classes.root}>

                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BeachAccessIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Service Category "
                                                  secondary={serviceData.sub_cat_name ? serviceData.sub_cat_name : ''}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Qualifications"
                                                  secondary={serviceData.qualification}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Speciality"
                                                  secondary={serviceData.speciality}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <LocationOn/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Location"
                                                  secondary={serviceData.city ? serviceData.city : ''}/>
                                </ListItem>


                            </List>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {serviceData.available ? (
                                        <Chip
                                            variant="outlined"
                                            size="large"
                                            className={classes.chip}
                                            label="Weekends Available"
                                            clickable
                                            color="default"
                                            deleteIcon={<DoneIcon/>}
                                        />
                                    ) : (
                                        <Chip
                                            variant="outlined"
                                            size="large"
                                            className={classes.chipred}
                                            label="Weekends Not Available"
                                            clickable
                                            color="default"
                                            deleteIcon={<DoneIcon/>}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="p" color="textPrimary">
                                        General Hours
                                    </Typography>
                                    <Typography variant="button" display="block" gutterBottom>
                                        {serviceData.service_time ? serviceData.service_time : ''}
                                        - TO - {serviceData.service_end_time ? serviceData.service_end_time : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="p" color="textPrimary">
                                        Consultant Fee
                                    </Typography>
                                    <Typography variant="button" display="block" gutterBottom>
                                        {serviceData.price ? serviceData.price : ''} LKR
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" className={classes.Act}
                                            onClick={() => getAvailableTimes(serviceData.slug ? serviceData.slug : null)}>
                                        Book Now
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>

                    </Grid>


                </Grid>
                <AvailableSlot
                    open={open}
                    setOpen={setOpen}
                    availableTimes={availableTimes}
                    serviceData={serviceData}
                    handleClose={() => handleClose}
                />
            </Container>
        </div>
    )

}
export default ServiceView
