import React, {useEffect, useState} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from 'react-router-dom';
import BOOKING_SEVICES from '../../services/Booking.service';
import AUTH_SEVICES from '../../services/auth.service';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import apiEndPoint from "../../constant/apiEndpoint";
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert/Alert";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
    },
    paper1: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
        textAlign: "center"
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, 0%)'
    },
    root1: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    root3: {
        marginTop: 30,
        height: "100%",
    },
    rootContainer: {
        flexGrow: 1,
        marginTop: 0,
        marginBottom: 50
    },
    pricing: {
        marginTop: 10,

    },
    name: {
        color: "#000",
        margin: 12,
        textTransform: 'capitalize'
    },
    completeAlert: {
        marginTop: theme.spacing(2)
    },
    btnComplete: {
        float: 'flex-end'
    },
    chip: {
        marginTop: 20,
        fontSize: 10,
        color: 'green',
    },
    chipred: {
        marginTop: 20,
        fontSize: 10,
        color: 'red',
    },
    sameCardBody: {
        // height: "100%",

    },
    addNewService: {
    marginTop : 50
    }

}));
const Profile = () => {
    const classes = useStyles();
    const history = useHistory();
    const [myServices, setMyServices] = useState([]);
    const [myProfile, setMyProfileData] = useState([]);
    const [imagePath, setImagePath] = React.useState('');
    const [serviceImageUrl, setServiceImagePath] = React.useState('');
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: false,
    });
    const [backDropopen, setBackDropOpen] = React.useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [open, setOpen] = React.useState(false);
    const handleCloseSnackbar = () => {
        setOpen(false);
    };
    const [values, setValues] = useState({});
    const AddService = () => {
        history.push("/add-service");
    };
    const EditProfile = () => {
        history.push("/edit-profile");
    };
    const EditService = (serviceSlug) => {
        history.push("/edit-service/" + serviceSlug);
    };
    const getServices = () => {
        return new Promise((resolve => {
            BOOKING_SEVICES.getServices()
                .then(res => {
                    if (res.data.services) {
                        setMyServices(res.data.services)
                    }

                    if (res.data.services) {
                        let url = apiEndPoint.base.baseAPI.local.hostName + 'serviceImages/';
                        setServiceImagePath(url);

                    }
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const handlePause = (e) => {

        let vals = values;

        if (e.target.checked) {
            const status = 2;
            vals['id'] = e.target.id;
            vals['status'] = status;
            setValues(vals);


        } else {
            const status = 1;
            vals['id'] = e.target.id;
            vals['status'] = status;
            setValues(vals);


        }
        setTimeout(() => {
            BOOKING_SEVICES.serviceStatus(vals)
                .then(res => {
                    console.log(res);
                    if (res.data.service) {
                        setTimeout(() => {
                        }, 1000)
                    }
                }).catch(() => {
                return false
            })
        }, 100);

    };
    const deleteService = async (val) => {

        let slug = val;
        BOOKING_SEVICES.deleteService({id: slug})
            .then(res => {

                if (res.data.services) {
                    setMyServices(res.data.services)
                }
            }).catch(() => {
            return false
        })

    };
    const resendEmail = () => {
        return new Promise((resolve => {
            setBackDropOpen(true);
            AUTH_SEVICES.resendEmail()
                .then(res => {
                    if (res.data.msg) {
                        setAlertMsg(res.data.msg);
                        setBackDropOpen(false);
                        setOpen(true);
                    }
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const getUserData = () => {
        return new Promise((resolve => {
            AUTH_SEVICES.getUserData()
                .then(res => {
                    if (res.data.userData) {
                        setMyProfileData(res.data.userData);
                        if (res.data.userData.image) {
                            let path = apiEndPoint.base.baseAPI.local.hostName + 'userprofile/' + res.data.userData.image;
                            setImagePath(path);
                        }
                    }
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    useEffect(() => {
        if (!localStorage.getItem('access_token') && !localStorage.getItem('isLoggedIn') && localStorage.getItem('isLoggedIn') != 'true') {
            history.push("/login");
        }
        getServices();
        getUserData();
    }, []);
    function AlertInfo(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    return (
        <div className={classes.root}>
            <Grid item xs={12}>

                {myProfile && !myProfile.country ? (  <AlertInfo severity="info">You have to complete profile details before add services </AlertInfo>) :''}

            </Grid>
            <Container>

                <Grid container spacing={1}>
                    <Grid item xs={12}>

                        {myProfile && myProfile.email_verified_at === null ? (
                            <Alert className={classes.completeAlert} severity="warning"
                                   action={<Button className={classes.btnComplete} size="small" variant="outlined"
                                                   color="primary" onClick={() => resendEmail()}> Re-send </Button>}>Please
                                verify your email address.

                            </Alert>
                        ) : ''}

                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className={classes.paper1}>
                            <Avatar alt="Remy Sharp"
                                    src={imagePath ? imagePath : ""}
                                    className={classes.large}/>
                            <h3 className={classes.name}>{myProfile.firstName} {myProfile.lastName}</h3>
                            <h5>{myProfile.username}</h5>
                            <IconButton aria-label="Edit Account" onClick={() => EditProfile()}>
                                <EditIcon/>
                            </IconButton>
                            {/*<IconButton aria-label="Delete Account">*/}
                            {/*<DeleteIcon/>*/}
                            {/*</IconButton>*/}
                            <List className={classes.root3}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Phone" secondary={myProfile.phone}/>
                                </ListItem>
                                {/*<ListItem>*/}
                                {/*<ListItemAvatar>*/}
                                {/*<Avatar>*/}
                                {/*<WorkIcon/>*/}
                                {/*</Avatar>*/}
                                {/*</ListItemAvatar>*/}
                                {/*<ListItemText primary="Address" secondary="282/4, Uyanwatta road, polgolla*/}
                                {/*"/>*/}
                                {/*</ListItem>*/}
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Birth Day" secondary={myProfile.dob}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BeachAccessIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Gender " secondary={myProfile.gender}/>
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} className={classes.rootContainer}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <h3>My Services</h3>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" color="primary" onClick={() => AddService()}>
                                        Add New +
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container spacing={2}>
                            {
                                myServices && myServices.length ? (
                                    myServices.map(service => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Card className={classes.root3}>
                                                    <CardActionArea className={classes.sameCardBody}
                                                                    href={`/service/${service.slug}`}>
                                                        <CardMedia
                                                            className={classes.media}
                                                            image={serviceImageUrl + service.image}
                                                        />
                                                        <CardContent>
                                                            <Typography gutterBottom variant="h6" component="h2">
                                                                {service.service_title}
                                                            </Typography>
                                                            <Typography variant="body2" noWrap color="textSecondary"
                                                                        component="p">
                                                                {service.description}
                                                            </Typography>
                                                            <Typography variant="p" color="textPrimary" component="p"
                                                                        className={classes.pricing}>
                                                                Category: {service.sub_cat_name}
                                                            </Typography>
                                                            <Typography variant="p" color="textPrimary" component="p"
                                                                        className={classes.pricing}>
                                                                General
                                                                Hour: {service.service_time} - {service.service_end_time}
                                                            </Typography>
                                                            <Typography variant="p" color="textPrimary" component="p">
                                                                Price: {service.price} LKR
                                                            </Typography>
                                                            {service.available ? (
                                                                <Chip
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className={classes.chip}
                                                                    label="Weekends Available"
                                                                    clickable
                                                                    color="default"
                                                                    deleteIcon={<DoneIcon/>}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className={classes.chipred}
                                                                    label="Weekends Not Available"
                                                                    clickable
                                                                    color="default"
                                                                    deleteIcon={<DoneIcon/>}
                                                                />
                                                            )}


                                                        </CardContent>
                                                    </CardActionArea>
                                                    <CardActions>
                                                        <Button variant="outlined" size="small" color="secondary"
                                                                onClick={() => deleteService(service.id)}>
                                                            Delete
                                                        </Button>
                                                        <Button variant="outlined" size="small" color="primary"
                                                                onClick={() => EditService(service.slug)}>
                                                            Edit
                                                        </Button>
                                                        {service.status === 2 ? (
                                                            <FormControlLabel
                                                                value="Pause"

                                                                control={<Switch color="secondary"
                                                                                 defaultChecked
                                                                                 onChange={handlePause}
                                                                                 id={service.id}
                                                                />}
                                                                label="Pause"
                                                                labelPlacement="start"
                                                            />
                                                        ) : (
                                                            <FormControlLabel
                                                                value="Pause"

                                                                control={<Switch color="secondary"

                                                                                 onChange={handlePause}
                                                                                 id={service.id}
                                                                />}
                                                                label="Pause"
                                                                labelPlacement="start"
                                                            />
                                                        )}

                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )
                                    })
                                ) :(
                                    <Grid item xs={12}>
                                        <center>
                                            <Button variant="contained" color="primary" className={classes.addNewService} onClick={() => AddService()}>
                                                Add Your First Service
                                            </Button>
                                        </center>
                                    </Grid>


                                )
                            }
                        </Grid>
                    </Grid>

                </Grid>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success">
                        {alertMsg}
                    </Alert>
                </Snackbar>
                <Backdrop className={classes.backdrop} open={backDropopen}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </div>
    )

};
export default Profile
