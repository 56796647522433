import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Backdrop from '@material-ui/core/Backdrop';
import Container from '@material-ui/core/Container';
import Card from '../Reusable/Item.component';
import { Helmet } from "react-helmet";
import BOOKING_SEVICES from "../../services/Booking.service";
import { Formik } from 'formik';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Link from "@material-ui/core/Link/Link";


const useStyles = makeStyles(theme => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 368,
        position: 'relative',
        backgroundSize: 'cover',
        background: 'linear-gradient(45deg, #4f75b3 30%, #49a09d 90%)',
        borderRadius: 0
    },
    label: {
        color: "white",
        "&.Mui-focused": {
            color: "white",
        },
    },
    searchButton: {
        marginTop: 0,
        height: 45,
        width: 238,
    },
    filters: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,

    },
    formControl: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
    },
    searchArea: {
        marginTop: 30,
        marginLeft: 0,
        marginRight: 0,
    },

    search: {
        position: 'relative',
        color: 'white',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        width: '100%',
        height: 55,
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchCategory: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        width: '100%',
        height: 55,
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: '#fff',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        paddingTop: `calc(1em + ${theme.spacing(0.4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    label2: {
        textTransform: 'capitalize',
    },
    root: {
        border: 0,
    },
    searchHeader: {
        marginTop: 20,
        color: '#fff',
        textTransform: 'capitalize'
    },
    rootPagination: {
        '& > *': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(10),
            justifyContent: "center",
            display: 'flex'
        },
    },

}));


const Home = () => {
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [cities, setCities] = useState([]);
    const [backDrop, setBackDropOpen] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    let pageNumber = 1;
    let [searchTerms, setSearchTerms] = useState({
        location: '',
        category: '',
        keyword: '',
    });
    const getCategories = () => {
        return new Promise((resolve => {
            BOOKING_SEVICES.getCategories()
                .then(res => {
                    if (res.data.categories) {
                        setCategories(res.data.categories)
                    }
                }).catch(() => {
                    return resolve(false)
                })
        }))
    };
    const getLocations = () => {
        return new Promise((resolve => {
            BOOKING_SEVICES.getLocations()
                .then(res => {
                    setCities(res.data.cities)
                }).catch(() => {
                    return resolve(false)
                })
        }))
    };
    const getServices = (values, page) => {
        return new Promise((resolve => {

            setBackDropOpen(true);
            BOOKING_SEVICES.getAllServices(values, pageNumber)
                .then(res => {
                    if (res.data.services) {
                        setSearchResult(res.data.services.data);
                        setPageCount(res.data.services.last_page);
                        setBackDropOpen(false);
                    }
                }).catch(() => {
                    return resolve(false)
                })
        }))
    };
    const changePagination = (event, value) => {
        pageNumber = value;
        setPage(value);
        getServices(searchTerms);
    };
    useEffect(() => {
        getServices(searchTerms);
        getCategories();
        getLocations();
    }, []);
    return (

        <Grid item xs={12}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Staane.com</title>

            </Helmet>
            <Paper className={classes.paper}>
                <div>
                    <div mt={2} className={classes.searchHeader}>
                        <Typography variant="h4" gutterBottom className='home-main-text'>
                            Online advise when you need some help
                        </Typography>
                        <Typography variant="h6" gutterBottom className='home-secondary-text'>
                            It's easy. Search for the service provider and book a consultation.
                        </Typography>
                        <Typography variant="subtitle3" gutterBottom className='test'>
                            This site is new. Please bear with us if you see issue.
                        </Typography>
                    </div>
                    <Container>
                        <Formik
                            initialValues={{
                                location: '',
                                category: '',
                                keyword: '',
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setBackDropOpen(true);
                                setTimeout(() => {
                                    getServices(values);
                                    setSearchTerms(values);
                                    setSubmitting(false);
                                }, 100);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid item xs={12}
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={4}
                                        className={classes.searchArea}
                                        style={{ margin: "0px !important" }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item md={2} sm={12} xs={12}>
                                            </Grid>
                                            <Grid item md={4} sm={12} xs={12} >
                                                <Autocomplete
                                                    onBlur={handleBlur}
                                                    className={classes.searchCategory}
                                                    options={categories}
                                                    getOptionLabel={(option) => option.category_name ? option.category_name : ""}
                                                    onChange={(e, value) => values.category = value === null ? '' : value.id}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name="category"
                                                            label="Select Category"
                                                            value={values.category}
                                                            className={classes.label}
                                                            variant="filled" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={12} xs={12} display="flex" justifyContent="center" alignItems="center">
                                                <Autocomplete
                                                    className={classes.search}
                                                    onChange={(e, value) => values.location = value === null ? '' : value.id}
                                                    onBlur={handleBlur}
                                                    options={cities}
                                                    getOptionLabel={(option) => option.name ? option.name : ""}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name="location"
                                                            value={values.location}
                                                            label="Select Location"
                                                            variant="filled" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={2} sm={12} xs={12} >
                                            </Grid>

                                            <Grid item md={2} sm={12} xs={12}>
                                            </Grid>
                                            <Grid item xs={12} md={8} sm={12}>
                                                <div className={classes.search}>
                                                    <div className={classes.searchIcon}>
                                                        <SearchIcon />
                                                    </div>
                                                    <InputBase
                                                        name="keyword"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.keyword}
                                                        placeholder="Search Keyword…"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput,
                                                        }}
                                                        inputProps={{ 'aria-label': 'search' }}
                                                    />
                                                </div>

                                            </Grid>

                                            <Grid item md={2} sm={12} xs={12}>
                                            </Grid>
                                            <Grid item xs={12}
                                                container
                                                direction="column"
                                                justify="center"
                                                alignItems="center"
                                            // spacing={4}
                                            >
                                                <center>
                                                    <Button
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        variant="contained"
                                                        size="large"
                                                        type="submit"
                                                        color="primary"
                                                        className={`${classes.searchButton} home-search-btn`}
                                                        startIcon={<SearchIcon />}>
                                                        Search
                                                    </Button>
                                                </center>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Container>
                </div>
            </Paper>
            <Card
                searchResult={searchResult}
            />
            <div className={classes.rootPagination}>
                <center>
                    <Pagination count={pageCount} justifyContent='center' page={page} color="primary" onChange={changePagination} />
                </center>
            </div>
            <Backdrop className={classes.backdrop} open={backDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default Home
