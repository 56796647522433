import 'date-fns';
import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import {Formik, formik} from 'formik'
import FormHelperText from '@material-ui/core/FormHelperText'
import * as yup from 'yup';
import BOOKING_SEVICES from "../../services/Booking.service";
import AUTH_SERVICE from "../../services/auth.service";
import {useHistory} from "react-router";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import apiEndPoint from '../../constant/apiEndpoint';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginBottom: theme.spacing(7),
    },

    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(7),
    },
    paper1: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
        textAlign: "center"
    },

    root3: {
        marginTop: 30,
    },
    rootContainer: {
        flexGrow: 1,
        marginTop: 0,
        marginBottom: 50
    },
    formControl: {
        width: '100%',
        marginTop: 10
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    saveBtn: {
        marginTop: 10,
        width: '100%',
    },
    root1: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, 0%)'
    },
    input: {
        display: 'none',
    },

}));

const EditProfile = ({dispatch}) => {
    const classes = useStyles();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [countries, setCountries] = React.useState([]);
    const [myProfile, setMyProfileData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [imagePath, setImagePath] = React.useState('');
    const handleCloseSnackbar = () => {
        setOpen(false);
    };
    const getLocations = () => {
        return new Promise((resolve => {
            BOOKING_SEVICES.getCountries()
                .then(res => {
                    setCountries(res.data.countries)
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const getUserData = () => {
        return new Promise((resolve => {
            setLoading(true);
            AUTH_SERVICE.getUserData()
                .then(res => {
                    if (res.data.userData) {
                        setMyProfileData(res.data.userData);
                        if(res.data.userData.image){
                            let path = apiEndPoint.base.baseAPI.local.hostName + 'userprofile/' + res.data.userData.image;
                            setImagePath(path);
                        }
                        setLoading(false);
                    }
                }).catch(() => {
                return resolve(false)
            })
        }))
    };
    const changeProfilePic = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = e => {
                let data = {image: e.target.result};
                return new Promise((resolve => {
                    AUTH_SERVICE.updatePatientProfilePicture(
                        data,
                    ).then((res) => {
                        if (res.data.imageName) {
                            let path = apiEndPoint.base.baseAPI.local.hostName + 'userprofile/' + res.data.imageName;
                            setImagePath(path);
                            setOpen(true);
                        }
                    })
                        .catch(() => {
                            return resolve(false)
                        });
                }))

            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    useEffect(() => {
        getLocations();
        getUserData();
    }, []);
    return (
        <div className={classes.root}>
            { loading === false ? (
                <Formik
                    initialValues={{
                        firstName: myProfile && myProfile.firstName ? myProfile.firstName : '',
                        lastName: myProfile && myProfile.lastName ? myProfile.lastName : '',
                        email: myProfile && myProfile.email ? myProfile.email : '',
                        phone: myProfile && myProfile.phone ? myProfile.phone : '',
                        country: myProfile && myProfile.country ? myProfile.country : '',
                        dob: myProfile && myProfile.dob ? myProfile.dob : '',
                        gender: myProfile && myProfile.gender ? myProfile.gender : '',
                        speciality: myProfile && myProfile.speciality ? myProfile.speciality : '',
                        qualification: myProfile && myProfile.qualification ? myProfile.qualification : '',
                        description: myProfile && myProfile.description ? myProfile.description : '',
                    }}
                    validationSchema={yup.object().shape({
                        email: yup.string().email().required(),
                        password: yup.string().min(6),
                        firstName: yup.string().required(),
                        lastName: yup.string().required(),
                        country: yup.string().required(),
                        phone: yup.number(),
                        description: yup.string().required(),
                        qualification: yup.string().required(),
                        speciality: yup.string().required(),
                        dob: yup.date(),
                        password_confirmation: yup.string()
                            .oneOf([yup.ref('password'), null], 'Passwords must match')
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        AUTH_SERVICE.updateProfile(values)
                            .then(res => {
                                if (res.data) {
                                    localStorage.setItem('user', JSON.stringify(res.data.userData));
                                    setOpen(true);
                                    setInterval(() => {
                                        history.go("/");
                                    }, 2000);
                                }
                            })
                            .catch(() => {
                                return false;
                            });
                    }
                    }
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            {myProfile && !myProfile.country ? (  <Alert severity="info">You have to complete profile details before add services </Alert>) :''}
                            <Container>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <h3 style={{color: "#000", margin: 12,}}>Edit Profile</h3>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.paper1}>
                                            <Avatar alt="Remy Sharp"
                                                    src={ imagePath ? imagePath :"" }
                                                    className={classes.large}/>
                                            <input onChange={changeProfilePic} accept="image/*"
                                                   className={classes.input} id="icon-button-file"
                                                   type="file" name="picture"/>
                                            <label htmlFor="icon-button-file">
                                                <IconButton color="primary" aria-label="upload picture"
                                                            component="span">
                                                    <PhotoCamera/>
                                                </IconButton>
                                            </label>
                                            <h3 style={{color: "#000", margin: 12,}}>{ myProfile.username }</h3>
                                            <h5>{ myProfile.speciality }</h5>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="First Name"
                                                    id="FirstName"
                                                    name="firstName"
                                                    value={values.firstName}
                                                    className={classes.formControl}
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.firstName && touched.firstName && errors.firstName}
                                                    helperText={touched.firstName ? errors.firstName : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Last Name"
                                                    id="LastName"
                                                    name="lastName"
                                                    className={classes.formControl}
                                                    value={values.lastName}
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.lastName && touched.lastName && errors.lastName}
                                                    helperText={touched.lastName ? errors.lastName : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Email Address"
                                                    id="email"
                                                    name="email"
                                                    disabled={true}
                                                    value={values.email}
                                                    className={classes.formControl}
                                                    margin="normal"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    variant="outlined"
                                                    error={errors.email && touched.email && errors.email}
                                                    helperText={touched.email ? errors.email : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Phone Number"
                                                    id="phone"
                                                    name="phone"
                                                    value={values.phone}
                                                    className={classes.formControl}
                                                    margin="normal"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    variant="outlined"
                                                    error={errors.phone && touched.phone && errors.phone}
                                                    helperText={touched.phone ? errors.phone : ''}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                                {/*<KeyboardDatePicker*/}
                                                {/*margin="normal"*/}
                                                {/*id="date-picker-dialog"*/}
                                                {/*label="Date of birth"*/}
                                                {/*variant="outlined"*/}
                                                {/*name="dob"*/}
                                                {/*disableFuture*/}
                                                {/*openTo="year"*/}
                                                {/*error={errors.dob && touched.dob && errors.dob}*/}
                                                {/*helperText={touched.dob ? errors.dob : ''}*/}
                                                {/*format="yyyy/mm/dd"*/}
                                                {/*value={values.dob}*/}
                                                {/*onChange={date => {*/}
                                                {/*if (!moment(date).isValid()) {*/}
                                                {/*setFieldValue('dob', date);*/}
                                                {/*}*/}
                                                {/*else {*/}
                                                {/*setFieldValue(*/}
                                                {/*'dob',*/}
                                                {/*moment(date).format('yyyy/mm/dd')*/}
                                                {/*);*/}
                                                {/*}*/}
                                                {/*}}*/}
                                                {/*views={['year', 'month', 'date']}*/}
                                                {/*/>*/}
                                                {/*</MuiPickersUtilsProvider>*/}
                                                <TextField
                                                    label="Date of birth"
                                                    id="dob"
                                                    name="dob"
                                                    className={classes.formControl}
                                                    margin="normal"
                                                    variant="outlined"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={errors.dob && touched.dob && errors.dob}
                                                    helperText={touched.dob ? errors.dob : ''}
                                                    value={values.dob}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel id="Gender">Gender</InputLabel>
                                                    <Select
                                                        labelId="Gender"
                                                        id="gender"
                                                        name="gender"
                                                        label="Gender"
                                                        value={values.gender}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.gender && touched.gender && errors.gender}
                                                        helperText={touched.gender ? errors.gender : ''}
                                                    >
                                                        <MenuItem
                                                            key="0"
                                                            value="male"
                                                        >Male</MenuItem>
                                                        <MenuItem
                                                            key="0"
                                                            value="female">
                                                            Female</MenuItem>
                                                    </Select>

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel id="country">Service Location</InputLabel>
                                                    <Select
                                                        labelId="Location-label"
                                                        id="country"
                                                        value={values.country}
                                                        name="country"
                                                        label="Country"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.country && touched.country && errors.country}
                                                        helperText={touched.country ? errors.country : ''}
                                                    >
                                                        {
                                                            countries && countries.length > 0 ? (
                                                                countries.map(coun => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={coun.id}
                                                                            value={coun.id}
                                                                        >
                                                                            {coun.name}
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                            ) : ""
                                                        }

                                                    </Select>
                                                    {errors.country && touched.country ? (
                                                        <FormHelperText error={true}>{errors.country}</FormHelperText>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Description"
                                                    id="description"
                                                    name="description"
                                                    value={values.description}
                                                    className={classes.formControl}
                                                    multiline
                                                    rowsMax={4}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.description && touched.description && errors.description}
                                                    helperText={touched.description ? errors.description : ''}

                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Speciality"
                                                    id="speciality"
                                                    name="speciality"
                                                    value={values.speciality}
                                                    className={classes.formControl}
                                                    multiline
                                                    rowsMax={4}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.speciality && touched.speciality && errors.speciality}
                                                    helperText={touched.speciality ? errors.speciality : ''}

                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Qualification"
                                                    id="Qualification"
                                                    name="qualification"
                                                    value={values.qualification}
                                                    className={classes.formControl}
                                                    multiline
                                                    rowsMax={4}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.qualification && touched.qualification && errors.qualification}
                                                    helperText={touched.qualification ? errors.qualification : ''}

                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    label=" Change Password "
                                                    id="password"
                                                    name="password"
                                                    value={values.password}
                                                    className={classes.formControl}
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.password && touched.password && errors.password}
                                                    helperText={touched.password ? errors.password : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label=" Confirm Password "
                                                    id="password_confirmation"
                                                    name="password_confirmation"
                                                    value={values.password_confirmation}
                                                    className={classes.formControl}
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.password_confirmation && touched.password && errors.password_confirmation}
                                                    helperText={touched.password_confirmation ? errors.password_confirmation : ''}

                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>

                                            <Grid item xs={4}>
                                                <Button fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        size="large"
                                                    // disabled={isSubmitting}
                                                        className={classes.saveBtn}>
                                                    save
                                                </Button>

                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button onClick={() => {
                                                    history.push('/profile')
                                                }} variant="contained" size="large" color="Secondary"
                                                        className={classes.saveBtn}>
                                                    Back
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Container>
                        </form>

                    )}
                </Formik>) : ""}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Profile Updated Successfully
                </Alert>
            </Snackbar>
        </div>

    )

}
export default EditProfile
