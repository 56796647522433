import apiEndPoint from '../constant/apiEndpoint'
import HttpService from './Http.service'

const BOOKING_SEVICES = {

    checkAvailabilityThisWeek : params => {
        const url = apiEndPoint.booking.checkAvailability
            .replace('{slug}',params);
        return new Promise((resolve , reject) => {
          HttpService.get(url)
              .then(res => {
                return  resolve(res)
              })
              .catch(err => reject(err))
        })
    },
    getServiceDetails : params => {

        const url = apiEndPoint.service.editService
            .replace('{serviceSlug}',params);

        return new Promise((resolve , reject) => {
          HttpService.get(url)
              .then(res => {
                return  resolve(res)
              })
              .catch(err => reject(err))
        })
    },

    getServiceDetailsUser : params => {

        const url = apiEndPoint.service.getServiceDetails
            .replace('{serviceSlug}',params);

        return new Promise((resolve , reject) => {
          HttpService.get(url)
              .then(res => {
                return  resolve(res)
              })
              .catch(err => reject(err))
        })
    },


    creteBooking : params => {

    //     const bookingData = {
    //         providerId :  params.bookingData.providerId,
    //         serviceId :  params.bookingData.serviceId,
    //         slotId :  params.bookingData.slotId,
    //         date :  params.bookingData.date,
    //         time :  params.bookingData.time,
    //         paymentInfo : params.paymentInfo
    //
    // };

        const url = apiEndPoint.booking.creteBooking;

        return new Promise((resolve , reject) => {
          HttpService.post(url, params)
              .then(res => {

                  return resolve(res)
              })
              .catch(err => reject(err))
        })

    },
    getCategories : params => {

        const url = apiEndPoint.categories.getCategories;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getLocations : params => {

        const url = apiEndPoint.location.cities;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getCountries : params => {

        const url = apiEndPoint.location.countries;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getSlots : params => {

        const url = apiEndPoint.common.getSlots;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getSubCategories : id => {

        const url = apiEndPoint.categories.getSubCategories
            .replace('{categoryId}',id);

        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    createService : params => {
        const url = apiEndPoint.service.createService;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return resolve(res)
                })
                .catch(err => reject(err))
        })

    },
    updateService : params => {
        const url = apiEndPoint.service.updateService;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return resolve(res)
                })
                .catch(err => reject(err))
        })

    },
    serviceStatus : params => {
        const url = apiEndPoint.service.serviceStatus;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return resolve(res)
                })
                .catch(err => reject(err))
        })

    },
    deleteService : params => {
        const url = apiEndPoint.service.deleteService;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return resolve(res)
                })
                .catch(err => reject(err))
        })

    },
    getServices : params => {

        const url = apiEndPoint.service.getServices;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getAllServices : (values ,params) => {

        const category =values.category ? values.category :'';
        const location =values.location ? values.location :'';
        const keyword =values.keyword ? values.keyword :'';

        const url = apiEndPoint.home.getAllService+'?page='+params+'&category='+ category+'&location='+ location+'&keyword='+ keyword ;

        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },

    searchService : params => {
        const url = apiEndPoint.home.searchService;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getBooking : params => {
        const url = apiEndPoint.booking.getBooking;
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getMeetingData : params => {
        const url = apiEndPoint.booking.getMeeting;
        return new Promise((resolve , reject) => {
            HttpService.post(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    getPublicDetails : params => {
        const url = apiEndPoint.auth.getPublicData .replace('{username}',params);
        return new Promise((resolve , reject) => {
            HttpService.get(url,params)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    },
    editService : params => {
        const url = apiEndPoint.service.getServices.replace('serviceSlug', params);
        return new Promise((resolve , reject) => {
            HttpService.get(url)
                .then(res => {
                    return  resolve(res)
                })
                .catch(err => reject(err))
        })
    }
};

export default BOOKING_SEVICES
