import React, {useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import {useHistory} from 'react-router-dom';
import apiEndPoint from "../../constant/apiEndpoint";
import AvailableSlot from './AvailableSlot.component';
import BOOKING_SEVICES from "../../services/Booking.service";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import SaveIcon from '@material-ui/icons/Save';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight:theme.spacing(1),  
        marginBottom: 10
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        maxWidth: 280,
        marginTop: 20,
        alignItems: "left",
        textAlign: "left"
    },
    rootContainer: {
        flexGrow: 1,
        marginTop: 50,
        marginBottom: 50
    },
    description: {
        marginBottom: 5
    },
    descriptionSub: {
        marginTop: 20
    },
    providerArea: {
        display: 'flex',
       
    },
}));

const ImgMediaCard = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [myServices, setMyServices] = useState([]);
    const [availableTimes, setAvailbleTimes] = useState(false);
    const [backDropopen, setBackDropOpen] = React.useState(false);
    const url = apiEndPoint.base.baseAPI.local.hostName + 'serviceImages/';
    const profileImageUrl = apiEndPoint.base.baseAPI.local.hostName + 'userprofile/';
   
    const sellers = [
        {
            id: 1,
            name: 'personal trainer',
            email: 'somewhere@gmail.com'
        }, {
            id: 2,
            name: 'Dental Surgery',
            email: 'somewhere@gmail.com'
        }, {
            id: 3,
            name: 'Dental Surgery',
            email: 'somewhere@gmail.com'
        }, {
            id: 4,
            name: 'Dental Surgery',
            email: 'somewhere@gmail.com'
        },
        {
            id: 5,
            name: 'Dental Surgery',
            email: 'something@gmail.com'
        }

    ];
    const ViewService = (id) => {
        history.push("/service/" + id);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (props && props.searchResult) {
            setMyServices(props.searchResult)
        }
    }, [props]);
    return (
        <Container>
            <Grid container className={classes.rootContainer}>

                {
                    myServices && myServices.length ? (
                        myServices.map((service) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <center>
                                    <Card onClick={() => ViewService(service.slug)} className={classes.root}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                height="140"
                                                image={url+service.image}
                                                title="Contemplative Reptile"
                                                key={service.id}
                                            />
                                            <CardContent>                                            
                                                <Typography gutterBottom variant="subtitle2">
                                                    {service.service_title}

                                                </Typography>
                                                
                                                <Typography noWrap variant="caption" color="textSecondary"
                                                            className={classes.description}
                                                            component="p">
                                                    {service.description}
                                                </Typography>
                                                <div className={classes.providerArea}>
                                                        <Avatar src={service.profileImage ? profileImageUrl+service.profileImage : ""} className={classes.small} />  
                                                            <Typography gutterBottom noWrap variant="subtitle2" >
                                                             {service.firstName}  {service.lastName}
                                                             </Typography>  
                                                    </div>  
                                                <Divider/>
                                                <Grid container spacing={2} className={classes.descriptionSub}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">
                                                            Category : {service.sub_cat_name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">
                                                            Location : {service.city}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    </center>
                                </Grid>
                            );
                        })
                    ) : (
                        <center>
                            <Typography noWrap variant="h6" color="textSecondary"
                                        component="p">
                            </Typography>
                        </center>
                        )
                }
            </Grid>
            <AvailableSlot
                open={open}
                setOpen={setOpen}
                availableTimes={availableTimes}
                handleClose={() => handleClose}
            />

        </Container>
    );
};

export default (ImgMediaCard)
