const apiUrl = {
  local: {
    // hostName: 'http://127.0.0.1:8000/',
    hostName: 'https://staaneapi.scapenode.website/',
  },
};

export default {
  auth: {
    register: `${apiUrl.local.hostName}api/register`,
    login: `${apiUrl.local.hostName}api/login`,
    resetPassword: `${apiUrl.local.hostName}api/reset-password`,
    logout: `${apiUrl.local.hostName}api/logout`,
    getUser: `${apiUrl.local.hostName}api/getUser`,
    resendEmail: `${apiUrl.local.hostName}api/email/resend`,
    updateProfile: `${apiUrl.local.hostName}api/updateProfile`,
    updatePatientProfilePicture: `${apiUrl.local.hostName}api/updatePatientProfilePicture`,
    getPublicData: `${apiUrl.local.hostName}api/public/{username}`,
  },
  home: {
    getAllService: `${apiUrl.local.hostName}api/get-all-services`,
    searchService: `${apiUrl.local.hostName}api/search`,
  },
  booking: {
    getServiceDetails: `${apiUrl.local.hostName}api/get-service/{serviceSlug}`,
    checkAvailability: `${apiUrl.local.hostName}api/checkAvailability/{slug}`,
    creteBooking: `${apiUrl.local.hostName}api/booking`,
    getBooking: `${apiUrl.local.hostName}api/getBookings`,
    getMeeting: `${apiUrl.local.hostName}api/getMeeting`,
  },
  categories: {
    getCategories: `${apiUrl.local.hostName}api/categories`,
    getSubCategories: `${apiUrl.local.hostName}api/subCategories/{categoryId}`,
  },
  location: {
    cities: `${apiUrl.local.hostName}api/cities`,
    countries: `${apiUrl.local.hostName}api/countries`,
  },
  common: {
    getSlots: `${apiUrl.local.hostName}api/getSlots`,
  },
  service: {
    createService: `${apiUrl.local.hostName}api/service`,
    updateService: `${apiUrl.local.hostName}api/serviceUpdate`,
    serviceStatus: `${apiUrl.local.hostName}api/service-status/{id}`,
    deleteService: `${apiUrl.local.hostName}api/delete-service`,
    editService: `${apiUrl.local.hostName}api/editService/{serviceSlug}`,
    getServiceDetails: `${apiUrl.local.hostName}api/get-service/{serviceSlug}`,
    getServices: `${apiUrl.local.hostName}api/service`,
    // serviceWithUser : `${apiUrl.local.hostName}api/editService/{id}`,
  },
  base: {
    baseAPI: apiUrl,
  },
};
