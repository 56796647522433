import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import AUTH_SERVICE from '../../services/auth.service';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    errorList: {
        margin: theme.spacing(3, 0, 3),
    },
}));

export default function SignUp() {
    const classes = useStyles();
    const history = useHistory();
    const [alertMsg, setAlertMsg] = useState('');
    const [open, setOpen] = React.useState(false);
    const [errors, setErrorList] = useState([]);
    const [backDropopen, setBackDropOpen] = React.useState(false);
    const handleCloseSnackbar = () => {
        setOpen(false);
    };
    useEffect(() => {
       if(localStorage.getItem('access_token') && localStorage.getItem('isLoggedIn') === 'true'  ){
           history.push('/');
       }
    }, [history]);
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <div className={classes.errorList}>
                    {
                        errors && errors.length ? (
                            errors.map(error => {
                                return (
                                    <Alert variant="filled" severity="error">
                                        {error}
                                    </Alert>
                                )
                            })
                        ) :''
                    }
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        password_confirmation: '',
                        lastName: '',
                        firstName: '',
                    }}
                    validationSchema={yup.object().shape({
                        firstName: yup.string().required('First Name is required'),
                        lastName: yup.string().required('Last Name is required'),
                        email: yup.string().email().required('Email is required'),
                        password: yup.string().min(6,'Password must be at least 6 characters').required('Password is required'),
                        password_confirmation: yup.string().required(' Password Confirm is required')
                            .oneOf([yup.ref('password'), null], 'Passwords must match')
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setBackDropOpen(true);
                        setTimeout(() => {
                            AUTH_SERVICE.register(values)
                                .then(res=>{

                                  if (res.data.token){
                                      setBackDropOpen(false);
                                      setAlertMsg('Your account is created successfully');
                                      setOpen(true);
                                      setErrorList([]);
                                      setInterval(() => {
                                          history.push("/login")
                                      }, 2000);
                                  }
                                })
                                .catch( error =>{
                                    setBackDropOpen(false);
                                    setErrorList(error.response.data.errors);
                                    return false;
                            });
                            setSubmitting(false);
                        }, 100);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        autoFocus
                                        error={touched.firstName && Boolean(errors.firstName)}
                                        helperText={touched.firstName ? errors.firstName : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                        variant="outlined"
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        helperText={touched.lastName ? errors.lastName : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        autoComplete="email"
                                        error= {errors.email && touched.email && errors.email}
                                        helperText={touched.email ? errors.email : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Password"
                                        id="assword"
                                        autoComplete="current-password"
                                        error= {errors.password && touched.password && errors.password}
                                        helperText={touched.password ? errors.password : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        name="password_confirmation"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password_confirmation}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Password Confirm"
                                        id="password_confirmation"
                                        autoComplete="current-password"
                                        error= {errors.password_confirmation && touched.password_confirmation && errors.password_confirmation}
                                        helperText={touched.password_confirmation ? errors.password_confirmation : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}>
                                        Register
                                    </Button>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <Link href="/login" variant="body2">
                                                Already have an account? Sign in
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </form>
                    )}
                </Formik>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {alertMsg}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={backDropopen}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box mt={5}>
            </Box>
        </Container>
    );
}
