import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Login from './components/Auth/Login'
import Register from './components/Auth/Register'
import Home from './components/Pages/Home'
import ProfilePage from './components/Pages/Profile'
import AddServices from './components/Pages/AddService'
import MyBookings from './components/Pages/MyBookings'
import ServiceView from './components/Pages/ServiceView'
import EditService from './components/Pages/EditService'
import ThankYou from './components/Pages/ThankYou'
import EditProfile from './components/Pages/EditProfile'
import PublicProfile from './components/Pages/PublicProfile'
import EmailVerify from './components/Pages/EmailVerify'
import EmailNotVerify from './components/Pages/EmailNotVerify'
import ForgotPassword from './components/Pages/ForgotPassword'
import {ThemeProvider, createMuiTheme, makeStyles} from '@material-ui/core/styles';
import './App.css';
import Typography from "@material-ui/core/Typography/Typography";
import Link from "@material-ui/core/Link/Link";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'cursive',
        ].join(','),
    },});
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://staane.com">
                Staane.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
class App extends Component {


    render () {
        return (
            <BrowserRouter>
                <div>
                    <ThemeProvider theme={theme}>
                    <Header />
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/profile' component={ProfilePage} />
                        <Route exact path='/add-service' component={AddServices} />
                        <Route exact path='/service/:id' component={ServiceView} />
                        <Route exact path='/edit-service/:id' component={EditService} />
                        <Route exact path='/bookings' component={MyBookings} />
                        <Route exact path='/thankyou' component={ThankYou} />
                        <Route exact path='/login' component={Login} />
                        <Route exact path='/register' component={Register} />
                        <Route exact path='/forgot-password' component={ForgotPassword} />
                        <Route exact path='/Edit-Profile' component={EditProfile} />
                        <Route exact path='/public/:username' component={PublicProfile} />
                        <Route exact path='/email/verify/success' component={EmailVerify} />
                        <Route exact path='/email/verify/failed' component={EmailNotVerify} />
                    </Switch>
                    </ThemeProvider>
                   <Copyright/>
                </div>
            </BrowserRouter>
        )
    }
}

export default App;
