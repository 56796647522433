import React, {useState} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width:'40%',
        margin:'auto',

    },

    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(7),
        textAlign:'center',
    },



}));

const ThankYou = () => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Container>
                <Grid container spacing={12}>


                    <Grid item xs={12}>
                        <Paper className={classes.paper}>

                            <h3 style={{color: "green", margin: 12,}}>Thank You</h3>
                            <p>Your booking has been confirmed</p>
                            <p style={{color: "black", fontWeight: 600,}} > Please check your Email to get the Meeting Link </p>

                        </Paper>
                    </Grid>


                </Grid>


            </Container>
        </div>

    )

}
export default ThankYou
