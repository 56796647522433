import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import AUTH_SERVICE from '../../services/auth.service';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://staane.com">
                Staane.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    forgotPassword: {
        float: 'right',
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const history = useHistory();
    const [alertMsg, setAlertMsg] = useState('');
    const [open, setOpen] = React.useState(false);
    const [backDropopen, setBackDropOpen] = React.useState(false);
    const handleCloseSnackbar = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(localStorage.getItem('access_token') && localStorage.getItem('isLoggedIn') === 'true'  ){
            history.push('/');
        }
    }, [history]);
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={yup.object().shape({
                        email: yup.string().email('Should be a valid email address').required('Email is a required field'),
                        password: yup.string().min(6,'Password must be at least 6 characters').required('Password is a required field'),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setBackDropOpen(true);
                        setTimeout(() => {
                            AUTH_SERVICE.login(values)
                                .then(res=>{
                                    if (res.data.token){
                                        setBackDropOpen(false);
                                        localStorage.setItem('access_token',res.data.token);
                                        localStorage.setItem('user',JSON.stringify(res.data.user));
                                        localStorage.setItem('isLoggedIn','true');
                                        setInterval(() => {
                                            history.go("/");

                                        }, 1000);

                                    }
                                })
                                .catch((error=> {
                                    setBackDropOpen(false);
                                    setSubmitting(false);
                                    setOpen(true);
                                    setAlertMsg('Username or Password does not matched');
                                    return false;
                                }
                                ));

                        }, 100);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        autoComplete="email"
                                        error= {errors.email && touched.email && errors.email}
                                        helperText={touched.email ? errors.email : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Password"
                                        id="password"
                                        autoComplete="current-password"
                                        error= {errors.password && touched.password && errors.password}
                                        helperText={touched.password ? errors.password : ''}
                                    />
                                    <Link href="/forgot-password" variant="body2" className={classes.forgotPassword}>
                                        {"Forgot my password"}
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}>
                                       Login
                                    </Button>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <Link href="/register" variant="body2">
                                                {"Don't have an account? Sign Up"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </form>
                    )}
                </Formik>
            </div>
            <Backdrop className={classes.backdrop} open={backDropopen}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {alertMsg}
                </Alert>
            </Snackbar>
            <Box mt={8}>

            </Box>
        </Container>
    );
}
